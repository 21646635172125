import React from "react";
import styles from "./style.module.scss";
function TermsOfUse({ modalStyle }: { modalStyle?: boolean }) {
    return (
        <section
            className={`${styles["mainContainer"]} ${
                modalStyle ? styles["modalStyle"] : ""
            }`}
        >
            <h1>PremiumCoverage' Website Terms of Use</h1>
            <span className={styles["timeStyle"]}>
                Effective Date: September 2023
            </span>
            <p>
                These Terms of Use ("Terms") govern your use of the
                PremiumCoverage website{" "}
                <a href="https://premiumcoverage.com">premiumcoverage.com</a>,
                which is owned and operated by PremiumCoverage, Inc. ("we,"
                "us," or "our"). Please read these terms and conditions
                carefully, as they establish the terms and conditions governing
                your use of our Website. By accessing or using our Website, you
                agree to be bound by these Terms. If you do not agree to these
                Terms, please do not use our Website.
            </p>
            <ol className={styles["decimalStyle"]}>
                <li>
                    <h2>Privacy Statement</h2>
                    <p>
                        Our Privacy Statement, which governs data collection,
                        storage, and use, is incorporated into these Terms. We
                        encourage you to review the Privacy Statement.
                    </p>
                </li>
                <li>
                    <h2>Changes to Our Website</h2>
                    <p>
                        You acknowledge and understand that our Website,
                        including all content, may be modified or discontinued
                        at any time, at our sole discretion, without prior
                        notice. Any changes are subject to these Terms.
                    </p>
                </li>
                <li>
                    <h2>Ownership of Intellectual Property</h2>
                    <p>
                        All content, including text, graphics, photographs,
                        trademarks, logos, sounds, music, artwork, computer
                        code, and other materials ("Content") published on the
                        Website, is protected by intellectual property laws and
                        is owned or licensed by PremiumCoverage or its
                        licensors. You may not modify, create derivative works,
                        display, distribute, or exploit any portion of the
                        Content or software on our Website without our prior
                        written permission. You are prohibited from using any
                        automated or manual devices to copy, monitor, index, or
                        mine data from the Website. PremiumCoverage and their
                        logos are registered and/or unregistered trademarks and
                        may not be copied or imitated without our prior written
                        consent. Other trademarks, product names, and company
                        names on the Website belong to their respective owners.
                        These Terms do not grant you any license or right to use
                        our proprietary rights related to products sold on our
                        Website.
                    </p>
                </li>
                <li>
                    <h2>License to Use and Copy</h2>
                    <p>
                        We grant you a limited license to make personal use of
                        the Content on our Website. This license does not
                        include resale or commercial use of the Content or data
                        mining methods on our Website.
                    </p>
                </li>
                <li>
                    <h2>Restrictions on Your Use of Our Website</h2>
                    <p>
                        You agree not to download, modify, reproduce, adapt,
                        translate, reverse engineer, create derivative works,
                        publicly display, sell, rent, license, or exploit any
                        portion of our Website or Content. You may not remove
                        any copyright, trademark, or other proprietary rights
                        notice from our Website. Using any devices to retrieve
                        or index our Website is prohibited. Transmitting viruses
                        or harmful components to our Website is not allowed. You
                        must not use our Website to violate any applicable laws
                        or collect personal or non-personal data about others.
                    </p>
                </li>
                <li>
                    <h2>DMCA Notice</h2>
                    <p>
                        If you believe any item or content on our Website
                        infringes your copyright, please send a written notice
                        of copyright infringement to the address provided below.
                        Your notice must meet the requirements of the Digital
                        Millennium Copyright Act (17 U.S.C. §512). It should
                        include:
                    </p>
                    <ul>
                        <li>
                            <p>
                                6.1. A description of the copyrighted work you
                                claim has been infringed.
                            </p>
                        </li>
                        <li>
                            <p>
                                6.2. The location of the alleged copyrighted
                                work on our Website.
                            </p>
                        </li>
                        <li>
                            <p>
                                6.3. Your contact information, including name,
                                address, phone number, and email.
                            </p>
                        </li>
                        <li>
                            <p>
                                6.4. A statement that you have a good-faith
                                belief that the disputed use is not authorized
                                by the copyright owner or the law.
                            </p>
                        </li>
                        <li>
                            <p>
                                6.5. A statement, under penalty of perjury, that
                                the information in your notice is accurate and
                                that you are the copyright owner or authorized
                                to act on their behalf.
                            </p>
                        </li>
                        <li>
                            <p>
                                6.6. An electronic or physical signature of the
                                person authorized to act on behalf of the
                                copyright owner.
                            </p>
                        </li>
                    </ul>
                    <p>
                        Our address is: <br />
                        PremiumCoverage <br />
                        2810 N Church St #56440 <br />
                        Wilmington, DE 19802
                        <br />
                        Email:{" "}
                        <a href="mailto:info@premiumcoverage.com">
                            info@premiumcoverage.com
                        </a>{" "}
                        <br />
                        Phone Number:{" "}
                        <a href="tel:19293873001">+1 (929) 387-3001</a> <br />
                    </p>
                </li>
                <li>
                    <h2>Disclaimer</h2>
                    <p>
                        Your use of our Website and its content is at your own
                        risk. We make no representations or warranties of any
                        kind, express or implied, regarding the availability,
                        operation, and use of our Website. We disclaim all
                        warranties, including merchantability, fitness for a
                        particular purpose, non-infringement, and implied
                        warranties arising from the course of dealing or
                        performance. We do not guarantee the accuracy,
                        completeness, or currency of the information and content
                        accessible through our Website. We make no
                        representations that our Website will be uninterrupted,
                        secure, or free of errors or harmful components. No
                        advice or information, whether oral or written, obtained
                        from us or our customer service creates any warranty not
                        expressly stated in these Terms.
                    </p>
                </li>
                <li>
                    <h2>Limitation of Liability</h2>
                    <p>
                        In no event shall PremiumCoverage, its affiliates,
                        shareholders, directors, officers, employees, agents, or
                        representatives be liable to you or any third party for
                        any damages, including direct, indirect, special,
                        punitive, incidental, or consequential damages or loss
                        of profits, goodwill, revenue, business interruption, or
                        loss of data, arising from your use of our Website. This
                        limitation includes damages related to the disclosure or
                        misuse of your personal information, even if we have
                        been advised of the possibility of such damages. In all
                        events, our total maximum liability is limited to one
                        hundred U.S. dollars ($100.00).
                    </p>
                </li>
                <li>
                    <h2>
                        Disclaimer of Advertisements and Links to Third-Party
                        Websites
                    </h2>
                    <p>
                        We may display advertisements from third parties on our
                        Website. These advertisements may take various forms. We
                        are not responsible for the content of such
                        advertisements or linked third-party websites, nor for
                        any products, services, or materials related to these
                        advertisements or linked websites. Our display of
                        advertisements or links does not imply our endorsement
                        of such products, services, or websites. We are not
                        liable for any damage or loss arising from or related to
                        these advertisements, links, or websites.
                    </p>
                </li>
                <li>
                    <h2>Class Action Waiver and Binding Arbitration</h2>
                    <p>
                        All disputes between you and PremiumCoverage concerning
                        our Website and the products advertised on it will be
                        submitted to confidential arbitration. You and
                        PremiumCoverage agree to waive the right to participate
                        in class action litigation.
                    </p>
                    <ul>
                        <li>
                            <p>
                                10.1. Dispute Resolution: <br />
                                If you intend to seek arbitration, you must
                                first send us a written Notice of Dispute. The
                                Notice must be mailed to:
                            </p>
                            <p>
                                PremiumCoverage <br />
                                2810 N Church St #56440 <br />
                                Wilmington, DE 19802
                                <br />
                                Email:{" "}
                                <a href="mailto:info@premiumcoverage.com">
                                    info@premiumcoverage.com
                                </a>{" "}
                                <br />
                                Phone Number:{" "}
                                <a href="tel:19293873001">
                                    +1 (929) 387-3001
                                </a>{" "}
                                <br />
                            </p>
                            <p>
                                The Notice should describe the nature of the
                                claim or dispute and the specific relief sought.
                                If we cannot resolve the claim within thirty
                                (30) days of receiving the Notice, either party
                                may commence arbitration.
                            </p>
                            <p></p>
                        </li>
                        <li>
                            <p>
                                10.2. Arbitration Proceedings: <br />
                                Arbitration will be conducted under the rules of
                                the American Arbitration Association. The
                                arbitrator's award is binding and may be entered
                                in any court of competent jurisdiction. The
                                arbitrator may not consolidate your claim with
                                any other claim or preside over any form of a
                                representative, private attorney general, or
                                class proceeding.
                            </p>
                        </li>
                        <li>
                            <p>
                                10.3. Arbitration Fees: <br />
                                If you commence arbitration, we will reimburse
                                you for the arbitration filing fee, unless your
                                claim is for greater than $10,000, in which case
                                payment of fees shall be decided by AAA Rules.
                                We may also pay the necessary fees directly to
                                AAA. If the arbitrator finds your claim
                                frivolous, you agree to reimburse us for
                                arbitration fees.
                            </p>
                        </li>
                        <li>
                            <p>
                                10.4. Class Action Waiver: <br />
                                You agree not to act as a class representative
                                or participate in class action litigation
                                against us. This provision survives the
                                termination of your relationship with
                                PremiumCoverage.
                            </p>
                        </li>
                    </ul>
                </li>
                <li>
                    <h2>Applicable Law</h2>
                    <p>
                        By using our Website, you agree that the laws of [Your
                        Jurisdiction], without regard to conflict of laws
                        principles, govern these Terms and any disputes between
                        you and us.
                    </p>
                </li>
                <li>
                    <h2>General Terms</h2>
                    <p>
                        No agency, partnership, joint venture, or employment
                        relationship is created by these Terms. These Terms
                        constitute the entire agreement between you and
                        PremiumCoverage. Failure to enforce any right or
                        provision in these Terms does not waive the enforcement
                        of that right or provision. If any provision in these
                        Terms is found unenforceable, it will be limited to the
                        minimum extent necessary for these Terms to remain in
                        effect. These Terms are not transferable or
                        sublicensable without our consent. The headings in these
                        Terms are for convenience only and have no legal or
                        contractual effect. These Terms incorporate the posted
                        Privacy Statement on our Website. If you have any
                        questions about these Terms, please contact us at:
                    </p>
                    <p>
                        PremiumCoverage <br />
                        2810 N Church St #56440 <br />
                        Wilmington, DE 19802
                        <br />
                        Email:{" "}
                        <a href="mailto:info@premiumcoverage.com">
                            info@premiumcoverage.com
                        </a>{" "}
                        <br />
                        Phone Number:{" "}
                        <a href="tel:19293873001">+1 (929) 387-3001</a> <br />
                    </p>
                </li>
            </ol>
        </section>
    );
}

export default TermsOfUse;
