import React from "react";
import styles from "./style.module.scss";
function CcpaPrivacyNotice({ modalStyle }: { modalStyle?: boolean }) {
    return (
        <section
            className={`${styles["mainContainer"]} ${
                modalStyle ? styles["modalStyle"] : ""
            }`}
        >
            <h1>PRIVACY NOTICE FOR CALIFORNIA RESIDENTS</h1>
            <span className={styles["timeStyle"]}>
                Effective Date: September 2023
            </span>
            <p>
                This notice supplements PremiumCoverage' comprehensive privacy
                policy, accessible at{" "}
                <a href="https://premiumcoverage.com/privacy-policy">
                    https://premiumcoverage.com/privacy-policy
                </a>
                , and applies exclusively to individuals residing in the state
                of California ("California consumers" or "you"). We have
                prepared this notice in accordance with the California Consumer
                Privacy Act of 2018 (CCPA), and any terms referenced in the CCPA
                hold the same meaning when used in this notice.
            </p>
            <h2>Information We Gather </h2>
            <p>
                We gather information that directly or indirectly identifies,
                pertains to, describes, references, or could reasonably be
                connected with a specific consumer, household, or device
                ("personal information"). Personal information does not include:
            </p>
            <ol className={styles["decimalStyleOnlyLi"]}>
                <li>Publicly available data from government records.</li>
                <li>Deidentified or aggregated consumer data.</li>
            </ol>
            <p>
                Specifically, within the last twelve (12) months, our website
                has collected the following categories of personal information
                from consumers:
            </p>
            <h2>Category Examples Collected </h2>
            <ol className={styles["decimalStyle"]}>
                <li>
                    <h2>Identifiers</h2>
                    <p>
                        Examples include real names, aliases, postal addresses,
                        unique personal identifiers, online identifiers,
                        Internet Protocol (IP) addresses, email addresses,
                        account names, Social Security numbers, driver's license
                        numbers, passport numbers, or other similar identifiers.
                        YES, we do collect.
                    </p>
                </li>
                <li>
                    <h2>
                        Personal Information Categories Listed in the California
                        Customer Records Statute (Cal. Civ. Code § 1798.80(e))
                    </h2>
                    <p>
                        Examples include names, signatures, Social Security
                        numbers, physical characteristics, addresses, telephone
                        numbers, passport numbers, driver's license or state
                        identification card numbers, insurance policy numbers,
                        education, employment, employment history, bank account
                        numbers, credit card numbers, debit card numbers, or any
                        other financial information, medical information, or
                        health insurance information. Some personal information
                        in this category may overlap with other categories. YES,
                        we do collect.
                    </p>
                </li>
                <li>
                    <h2>
                        Protected Classification Characteristics Under
                        California or Federal Law
                    </h2>
                    <p>
                        Examples include age (40 years or older), race, color,
                        ancestry, national origin, citizenship, religion or
                        creed, marital status, medical condition, physical or
                        mental disability, sex (including gender, gender
                        identity, gender expression, pregnancy or childbirth,
                        and related medical conditions), sexual orientation,
                        veteran or military status, and genetic information
                        (including familial genetic information). YES, we do
                        collect.
                    </p>
                </li>
                <li>
                    <h2>Commercial Information</h2>
                    <p>
                        Examples include records of personal property, products
                        or services purchased, obtained, or considered, or other
                        purchasing or consuming histories or tendencies. YES, we
                        do collect.
                    </p>
                </li>
                <li>
                    <h2>Biometric Information</h2>
                    <p>
                        Examples include genetic, physiological, behavioral, and
                        biological characteristics or activity patterns used to
                        extract a template or other identifier or identifying
                        information, such as fingerprints, faceprints,
                        voiceprints, iris or retina scans, keystroke, gait, or
                        other physical patterns, and sleep, health, or exercise
                        data. NO, we do not collect.
                    </p>
                </li>
                <li>
                    <h2>Internet or Other Similar Network Activity</h2>
                    <p>
                        Examples include browsing history, search history, and
                        information on a consumer's interaction with a website,
                        application, or advertisement. YES, we do collect.
                    </p>
                </li>
                <li>
                    <h2>Geolocation Data</h2>
                    <p>
                        Examples include physical locations or movements. YES,
                        we do collect.
                    </p>
                </li>
                <li>
                    <h2>Sensory Data</h2>
                    <p>
                        Examples include audio, electronic, visual, thermal,
                        olfactory, or similar information. NO, we do not
                        collect.
                    </p>
                </li>
                <li>
                    <h2>Professional or Employment-Related Information</h2>
                    <p>
                        Examples include current or past job histories or
                        performance evaluations. NO, we do not collect.
                    </p>
                </li>
                <li>
                    <h2>
                        Non-Public Education Information (per the Family
                        Educational Rights and Privacy Act (20 U.S.C. Section
                        1232g, 34 C.F.R. Part 99))
                    </h2>
                    <p>
                        Examples include education records directly related to a
                        student maintained by an educational institution or a
                        party acting on its behalf, such as grades, transcripts,
                        class lists, student schedules, student identification
                        codes, student financial information, or student
                        disciplinary records. NO, we do not collect.
                    </p>
                </li>
                <li>
                    <h2>Inferences Drawn from Other Personal Information</h2>
                    <p>
                        Examples include profiles reflecting a person's
                        preferences, characteristics, psychological trends,
                        predispositions, behavior, attitudes, intelligence,
                        abilities, and aptitudes. NO, we do not collect.
                    </p>
                </li>
            </ol>
            <p>
                Our website obtains the categories of personal information
                listed above from the following sources:
            </p>
            <ul>
                <li>
                    Directly from you, such as the information you provide when
                    completing forms, purchasing products and services from our
                    network providers, or during the registration process on our
                    website.
                </li>
                <li>
                    Indirectly from you by observing your interactions on our
                    website.
                </li>
                <li>
                    From our marketing partners, which includes information
                    gathered while providing services to our marketing
                    affiliates.
                </li>
                <li>
                    From verification service providers, allowing us to update
                    and verify your existing information and offer information
                    about products and services that may interest you.
                </li>
            </ul>
            <h2>Use of Personal Information </h2>
            <p>
                We may use, disclose, or sell the personal information we
                collect for one or more of the following purposes:
            </p>
            <ul>
                <li>
                    To fulfill or meet the reason you provided the information.
                    For instance, if you provide your name and contact
                    information to request a price quote or inquire about our
                    products or services, we will use this personal information
                    to respond to your request. If you share your personal
                    information to purchase a product or service, we will use it
                    for order processing and delivery facilitation.
                </li>
                <li>
                    To provide, support, personalize, and enhance our website,
                    products, and services.
                </li>
                <li>
                    To send you offers for products and services from our
                    marketing affiliates via email.
                </li>
                <li>
                    To establish, maintain, customize, and secure your account
                    with us.
                </li>
                <li>
                    To process your requests, purchases, transactions, and
                    payments and prevent transactional fraud.
                </li>
                <li>
                    To collect demographic information, such as zip codes and
                    gender, to present offers that align with your interests.
                </li>
                <li>
                    To provide support and address your inquiries, including
                    investigation, issue resolution, and response improvement.
                </li>
                <li>
                    To personalize your website experience, we deliver relevant
                    content and product/service offerings, including targeted
                    advertisements, through our website, third-party platforms,
                    and via email or text message (with your consent, as
                    required by law).
                </li>
                <li>
                    To maintain the security and integrity of our website,
                    products, services, technology assets, and business.
                </li>
                <li>
                    For testing, research, analysis, and product development,
                    including enhancing and improving our website, products, and
                    services.
                </li>
                <li>
                    To comply with law enforcement requests and legal
                    obligations as mandated by applicable law, court orders, or
                    government regulations.
                </li>
                <li>
                    As described at the time of collecting your personal
                    information or as otherwise outlined in the CCPA.
                </li>
                <li>
                    To assess or conduct mergers, divestitures, restructurings,
                    reorganizations, dissolutions, or other sales or transfers
                    of some or all of our assets, whether as a going concern or
                    as part of bankruptcy, liquidation, or a similar process. In
                    such instances, personal information held by us concerning
                    our website users is among the assets transferred.
                </li>
            </ul>
            <p>
                We will not collect additional categories of personal
                information or utilize collected personal information for
                purposes that are substantially different, unrelated, or
                incompatible without prior notice.
            </p>
            <h2>Sharing Personal Information</h2>
            <p>
                We may disclose your personal information to third parties for
                business purposes or sell it, subject to your right to opt-out
                of such sales (see Personal Information Sales Opt-Out and Opt-In
                Rights). When we disclose personal information for business
                purposes, we enter into contracts specifying the purpose and
                requiring recipients to keep this information confidential,
                utilizing it only to fulfill the contract. The CCPA prohibits
                third parties from acquiring the personal information we hold
                from reselling it unless you have received explicit notice and
                an opportunity to opt-out of further sales.
            </p>
            <p>
                We share your personal information with the following categories
                of third parties:
            </p>
            <ul>
                <li>Service providers.</li>
                <li>Our affiliated entities.</li>
                <li>Data aggregators.</li>
            </ul>
            <p>
                Third parties. We share personal information with third parties
                if you or an authorized representative have granted us
                authorization to disclose your personal information to them for
                information regarding products and services provided by us or
                our third-party marketing affiliates.
            </p>
            <h2>Disclosure of Personal Information for Business Purposes</h2>
            <p>
                In the previous twelve (12) months, PremiumCoverage have
                disclosed the following categories of personal information for
                business purposes:
            </p>
            <ul>
                <li>
                    <strong>Category A:</strong> Identifiers.
                </li>
                <li>
                    <strong>Category B:</strong> California Customer Records
                    personal information categories.
                </li>
                <li>
                    <strong>Category C:</strong> Protected classification
                    characteristics under California or federal law.
                </li>
                <li>
                    <strong>Category D:</strong> Commercial information.
                </li>
                <li>
                    <strong>Category F:</strong> Internet or other similar
                    network activity.
                </li>
                <li>
                    <strong>Category G:</strong> Geolocation data.
                </li>
            </ul>
            <p>
                We disclose your personal information for business purposes to
                the following categories of third parties:
            </p>
            <ul>
                <li>Service providers.</li>
                <li>Our affiliated entities.</li>
                <li>Data aggregators.</li>
            </ul>
            <p>
                Third parties. We share personal information with third parties
                if you or an authorized representative have granted us
                authorization to disclose your personal information to them for
                information regarding products and services provided by us or
                our third-party marketing affiliates.
            </p>
            <h2>Sales of Personal Information </h2>
            <p>
                In the past twelve (12) months, PremiumCoverage has sold the
                following categories of personal information:
            </p>
            <ul>
                <li>A. Identifiers.</li>
                <li>
                    B. California Customer Records personal information
                    categories.
                </li>
                <li>
                    C. Protected classification characteristics under California
                    or federal law.
                </li>
                <li>D. Commercial information.</li>
                <li>F. Internet or other similar network activity.</li>
                <li>G. Geolocation data.</li>
            </ul>
            <p>
                We sell your personal information to the following categories of
                third parties:
            </p>
            <ul>
                <li>Service providers.</li>
                <li>Our affiliated entities.</li>
                <li>Data aggregators.</li>
            </ul>
            <p>
                Third parties. We share personal information with third parties
                if you or an authorized representative have granted us
                authorization to disclose your personal information to them for
                information regarding products and services provided by us or
                our third-party marketing affiliates.
            </p>
            <h2>Your Rights and Choices </h2>
            <p>
                The CCPA grants California consumers specific rights concerning
                their personal information. This section outlines your CCPA
                rights and explains how to exercise them.
            </p>
            <h2>Access to Specific Information and Data Portability Rights</h2>
            <p>
                You have the right to request that we disclose particular
                information to you about our collection and utilization of your
                personal information during the past 12 months. Upon receiving
                and verifying your verifiable consumer request (see Exercising
                Access, Data Portability, and Deletion Rights), we will provide
                you with:
            </p>
            <ul>
                <li>
                    The categories of personal information about you that we
                    collected.
                </li>
                <li>The sources of personal information collection.</li>
                <li>
                    Our business or commercial purpose for collecting or selling
                    the personal information.
                </li>
                <li>
                    The categories of third parties with whom we shared your
                    personal information.
                </li>
                <li>
                    The specific pieces of personal information collected about
                    you (also known as a data portability request).
                </li>
            </ul>
            <p>
                If we sold or disclosed your personal information for a business
                purpose, we would provide two separate lists specifying sales,
                outlining the personal information categories each category of
                recipient bought, and disclosures for a business purpose,
                detailing the personal information categories each category of
                recipient obtained.
            </p>
            <h2>Deletion Request Rights</h2>
            <p>
                You have the right to request that we delete any of your
                personal information collected from you and retained, subject to
                certain exceptions. Once we receive and verify your verifiable
                consumer request (see Exercising Access, Data Portability, and
                Deletion Rights), we will delete (and instruct our service
                providers to delete) your personal information from our records
                unless an exception applies.
            </p>
            <p>
                We may decline your deletion request if retaining the
                information is necessary to:
            </p>
            <ul>
                <li>
                    Complete the transaction for which we collected the personal
                    information, deliver a product or service you requested,
                    take actions reasonably anticipated within the context of
                    our ongoing business relationship with you, fulfill the
                    terms of a written warranty or product recall conducted in
                    accordance with federal law, or perform our contract with
                    you.
                </li>
                <li>
                    Detect security incidents, protect against malicious,
                    deceptive, fraudulent, or unlawful activities, or prosecute
                    those responsible for such actions.
                </li>
                <li>
                    Debug products to identify and rectify errors impairing
                    their intended functionality.
                </li>
                <li>
                    Exercise free speech, safeguard another consumer's rights to
                    exercise their free speech rights or fulfill another right
                    established by law.
                </li>
                <li>
                    Comply with the California Electronic Communications Privacy
                    Act (Cal. Penal Code § 1546 et seq.).
                </li>
                <li>
                    Engage in public or peer-reviewed scientific, historical, or
                    statistical research in the public interest, adhering to all
                    applicable ethics and privacy laws when deleting the
                    information would likely prevent or significantly impair the
                    research's accomplishment, provided you previously granted
                    informed consent.
                </li>
                <li>
                    Enable solely internal uses, aligning with consumer
                    expectations based on your relationship with us.
                </li>
                <li>Comply with a legal obligation.</li>
                <li>
                    Pursue other internal and lawful purposes for that
                    information compatible with the context in which you
                    initially provided it.
                </li>
            </ul>
            <h2>Exercising Access, Data Portability and Deletion Rights</h2>
            <p>
                To exercise the rights of access, data portability, and deletion
                described above, please submit a verifiable consumer request by
                emailing our data protection officer at the following email
                address{" "}
                <a href="mailto:info@premiumcoverage.com">
                    info@premiumcoverage.com
                </a>
            </p>
            <p>
                Only you or an authorized representative acting on your behalf
                may make a verifiable consumer request. You may also make a
                verifiable consumer request on behalf of your minor child.
            </p>
            <p>
                You may only make two verifiable consumer requests for access or
                data portability within a 12-month period. The verifiable
                consumer request must:
            </p>
            <ul>
                <li>
                    Include enough information to allow us to verify that you
                    are the person about whom we collected personal information
                    or an authorized representative, such as providing details
                    sufficient to enable us to comprehend, evaluate, and address
                    the request properly.
                </li>
            </ul>
            <p>
                We cannot respond to your request or provide you with personal
                information unless we can verify your identity or authority to
                make the request and confirm that the personal information
                relates to you.
            </p>
            <p>
                Creating an account with us is not mandatory to make a
                verifiable consumer request.
            </p>
            <p>
                We will only utilize personal information provided in a
                verifiable consumer request to verify the requestor's identity
                or authority to make the request.
            </p>
            <p>
                For instructions on exercising opt-out rights regarding personal
                information sales, see Personal Information Sales Opt-Out and
                Opt-In Rights.
            </p>
            <h2>Response Timing and Format</h2>
            <p>
                We aim to respond to a verifiable consumer request within 45
                days of receiving it. If we need more time (up to 90 days), we
                will notify you of the reason and extension period in writing.
            </p>
            <p>
                If you possess an account with us, we will send our written
                response to that account. If you do not have an account with us,
                we will provide our written response via mail or electronically,
                at your choice.
            </p>
            <p>
                Any information disclosed will cover only the 12-month period
                preceding the receipt of the verifiable consumer request. The
                response will also clarify any reasons for our inability to
                comply with a request, if applicable. For data portability
                requests, we will select a format that makes your personal
                information readily usable and transferrable, allowing you to
                transmit it from one entity to another entity without hindrance.
            </p>
            <p>
                We do not impose a fee for processing or responding to
                verifiable consumer requests unless they are excessive,
                repetitive, or clearly unfounded. If we determine a fee is
                warranted, we will explain why and provide a cost estimate
                before fulfilling your request.
            </p>
            <h2>Personal Information Sales Opt-Out and Opt-In Rights</h2>
            <p>
                If you are at least 16 years old, you have the right to instruct
                us not to sell your personal information at any time (the
                "opt-out right"). We do not sell personal information belonging
                to consumers we know are under 16 years of age. Consumers who
                opt-in to personal information sales can opt-out of future sales
                at any moment.
            </p>
            <p>
                To exercise the opt-out right, you (or your authorized
                representative) can submit a request to us by emailing our data
                protection officer at the following email address:{" "}
                <a href="mailto:info@premiumcoverage.com">
                    info@premiumcoverage.com
                </a>{" "}
                or by clicking the following link: Do Not Sell My Personal
                Information.
            </p>
            <p>
                Once you make an opt-out request, we will wait at least twelve
                (12) months before requesting your authorization for personal
                information sales again. However, you may change your mind and
                opt back into personal information sales at any time by
                contacting us at the following email address:{" "}
                <a href="mailto:info@premiumcoverage.com">
                    info@premiumcoverage.com
                </a>
                .{" "}
            </p>
            <p>
                Creating an account with us is not necessary to exercise your
                opt-out rights. We will only use personal information provided
                in an opt-out request to review and comply with the request.
            </p>
            <h2>Non-Discrimination</h2>
            <p>
                We will not discriminate against you for exercising any of your
                CCPA rights. Unless permitted by the CCPA, we will not:
            </p>
            <ul>
                <li>Refuse to provide you with goods or services.</li>
                <li>
                    Charge you different prices or rates for goods or services,
                    including by granting discounts or other benefits or
                    imposing penalties.
                </li>
                <li>
                    Offer you a different level or quality of goods or services.
                </li>
                <li>
                    Suggest that you might receive a different price or rate for
                    goods or services or a different level or quality of goods
                    or services.
                </li>
            </ul>
            <h2>Other California Privacy Rights</h2>
            <p>
                California's "Shine the Light" law (Civil Code Section §
                1798.83) permits users of our website who are California
                residents to request specific information regarding our
                disclosure of personal information to third parties for their
                direct marketing purposes. To make such a request, please send
                an email to{" "}
                <a href="mailto:info@premiumcoverage.com">
                    info@premiumcoverage.com
                </a>
                .
            </p>
            <h2>Changes to Our Privacy Notice</h2>
            <p>
                We retain the right to revise this privacy notice at our
                discretion and at any time. When we make changes to this privacy
                notice, we will publish the updated notice on our website and
                adjust the notice's effective date accordingly. Your continued
                use of our website following the posting of changes implies your
                acceptance of those changes.
            </p>
            <h2>Contact Information</h2>
            <p>
                If you have any questions or comments about this notice, how
                PremiumCoverage collects and uses your information, as described
                here and in the Privacy Policy, your options and rights
                regarding such use, or if you wish to exercise your rights under
                California law, please do not hesitate to contact us at:
            </p>
            <div>
                <address>
                    PremiumCoverage
                    <br />
                    2810 N Church St #56440 <br />
                    Wilmington, DE 19802
                    <br />
                    Email:{" "}
                    <a href="mailto:info@premiumcoverage.com">
                        info@premiumcoverage.com
                    </a>{" "}
                    <br />
                    Phone Number:{" "}
                    <a href="tel:19293873001">+1 (929) 387-3001</a>
                </address>
            </div>
        </section>
    );
}

export default CcpaPrivacyNotice;
